'use client'

import { cva } from 'class-variance-authority'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn, truncateTextWithDot } from '~/core/ui/utils'

const chipVariants = cva(
  'rounded flex items-center border-[1px] border-solid bg-white dark:bg-gray-900 disabled:pointer-events-none disabled:border-gray-200 disabled:text-gray-400 dark:disabled:border-gray-700 dark:disabled:text-gray-700 border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 hover:bg-gray-50 focus:ring-1 focus:ring-primary-300 dark:hover:bg-gray-800 dark:focus:ring-primary-700',
  {
    variants: {
      variant: {
        4: 'pl-3',
        3: 'pl-2.5',
        2: 'pl-2',
        1: 'pl-1.5'
      },
      size: {
        lg: 'pr-3 py-1.5 h-8',
        md: 'pr-2.5 py-0.5 h-6',
        sm: 'pr-2 py-px h-5'
      }
    },
    defaultVariants: {
      variant: 4,
      size: 'lg'
    }
  }
)

const chipLabelVariants = cva('', {
  variants: {
    disabled: {
      disabled:
        'pointer-events-none border-gray-200 text-gray-400 dark:border-gray-700 dark:text-gray-700',
      default:
        'border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 hover:bg-gray-50 focus:ring-1 focus:ring-primary-300 dark:hover:bg-gray-800 dark:focus:ring-primary-700'
    },
    size: {
      lg: 'text-sm',
      md: 'text-sm',
      sm: 'text-xs'
    }
  },
  defaultVariants: {
    size: 'lg',
    disabled: 'default'
  }
})

const getSuggestionChipPaddingCondition = ({
  size = 'lg',
  type = 'default'
}: {
  size?: 'lg' | 'md' | 'sm'
  type?: 'default' | 'icon-leading'
}) => {
  if (size === 'lg' && type === 'default') return 4

  if (
    (size === 'md' && type === 'default') ||
    (size === 'lg' && type === 'icon-leading')
  ) {
    return 3
  }

  if (
    (size === 'sm' && type === 'default') ||
    (size === 'md' && type === 'icon-leading')
  ) {
    return 2
  }

  if (size === 'sm' && type === 'icon-leading') return 1

  return 4
}

const suggestionChipDefaultSizeIcon: { [key: string]: number } = {
  lg: 16,
  md: 14,
  sm: 12
}

interface SuggestionChipsGeneralProps {
  size?: 'lg' | 'md' | 'sm'
  type?: 'default' | 'icon-leading'
  isDisabled?: boolean
  isRemovable?: boolean
  classNameChip?: string
}

type SuggestionChipsProps = SuggestionChipsGeneralProps & {
  label?: string
  iconLeading?: LucideIconName
  index?: number
  onClick?: () => void
  maxLength?: number
}

type SuggestionChipsSelectProps = SuggestionChipsGeneralProps & {
  source: Array<SuggestionChipsProps>
  maxItems?: number
  className?: string
}

const SuggestionChipsItem = ({
  index,
  maxLength,
  size = 'lg',
  type = 'default',
  isRemovable = false,
  label,
  isDisabled = false,
  onClick,
  classNameChip = ''
}: SuggestionChipsProps) => {
  return (
    <button
      type="button"
      className={cn(
        'line-clamp-1',
        chipVariants({
          variant: getSuggestionChipPaddingCondition({ size, type }),
          size,
          className: classNameChip
        })
      )}
      disabled={isDisabled}
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}>
      <>
        {label && maxLength && label?.length > maxLength ? (
          <Tooltip content={label} classNameAsChild="line-clamp-1">
            <span
              className={cn(
                'line-clamp-1 break-all',
                chipLabelVariants({
                  size,
                  disabled: isDisabled ? 'disabled' : 'default',
                  className: classNameChip
                }),
              )}>
              {truncateTextWithDot(label, maxLength)}
            </span>
          </Tooltip>
        ) : (
          <span
            className={cn(
              'line-clamp-1 break-all',
              chipLabelVariants({
                size,
                disabled: isDisabled ? 'disabled' : 'default',
                className: classNameChip
              }),
            )}>
            {label}
          </span>
        )}
        {isRemovable && (
          <span className="ml-1.5 cursor-pointer">
            <IconWrapper
              name="X"
              size={suggestionChipDefaultSizeIcon[size]}
              className={
                isDisabled === false
                  ? 'text-gray-700 dark:text-gray-300'
                  : 'text-gray-400 dark:text-gray-700'
              }
            />
          </span>
        )}
      </>
    </button>
  )
}

const SuggestionChips = (props: SuggestionChipsSelectProps) => {
  const { maxItems = 0, className, source = [], ...otherProps } = props
  return (
    <div className={`-mt-2 flex flex-wrap items-center ${className || ''}`}>
      {(maxItems > 0 ? source.slice(0, maxItems) : source).map(
        (suggestionChip, index) => {
          return (
            <div
              key={`suggestion-chip-${index}`}
              className="mr-2 mt-2 last:mr-0 flex-none max-w-full">
              <SuggestionChipsItem
                index={index}
                {...otherProps}
                {...suggestionChip}
              />
            </div>
          )
        }
      )}
      {maxItems > 0 && maxItems < source.length && (
        <Tooltip
          align="center"
          content={source.slice(maxItems).map((suggestionChip, index) => (
            <div
              key={`suggestion-tooltip-${index}`}
              className={
                suggestionChip.onClick ? 'cursor-pointer hover:underline' : ''
              }
              onClick={() => {
                if (suggestionChip.onClick) {
                  suggestionChip.onClick()
                }
              }}>
              {suggestionChip.label}
            </div>
          ))}
          position="top">
          <div className="mr-2 mt-2 last:mr-0 flex-none max-w-full">
            <SuggestionChipsItem
              index={9999}
              {...otherProps}
              label={`+${source.length - maxItems}`}
            />
          </div>
        </Tooltip>
      )}
    </div>
  )
}

const SuggestionInlineChips = (props: SuggestionChipsSelectProps) => {
  const { maxItems = 0, className, source = [], ...otherProps } = props
  return (
    <>
      {(maxItems > 0 ? source.slice(0, maxItems) : source).map(
        (suggestionChip, index) => {
          return (
            <div
              key={`suggestion-chip-${index}`}
              className="mr-2 mt-2 last:mr-0 flex-none max-w-full">
              <SuggestionChipsItem
                index={index}
                {...otherProps}
                {...suggestionChip}
              />
            </div>
          )
        }
      )}
      {maxItems > 0 && maxItems < source.length && (
        <Tooltip
          align="center"
          classNameAsChild="flex-none"
          content={source.slice(maxItems).map((suggestionChip, index) => (
            <div
              key={`suggestion-tooltip-${index}`}
              className={
                suggestionChip.onClick ? 'cursor-pointer hover:underline' : ''
              }
              onClick={() => {
                if (suggestionChip.onClick) {
                  suggestionChip.onClick()
                }
              }}>
              {suggestionChip.label}
            </div>
          ))}
          position="top">
          <div className="mr-2 mt-2 last:mr-0 flex-none max-w-full">
            <SuggestionChipsItem
              index={9999}
              {...otherProps}
              label={`+${source.length - maxItems}`}
            />
          </div>
        </Tooltip>
      )}
    </>
  )
}

export { SuggestionChips, SuggestionInlineChips, SuggestionChipsItem }
export type {
  SuggestionChipsGeneralProps,
  SuggestionChipsSelectProps,
  SuggestionChipsProps
}
